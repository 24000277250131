import styled, { css } from 'styled-components'
import { styleParams } from 'helpers/styleHelpers/styleHelpers'
import { media } from 'constants/media/media'

export const P = styled.p`
	${styleParams};
	font-size: 1.6rem;
	line-height: 1.75;
`

export const PLarge = styled.p`
	${styleParams};
	font-size: 1.8rem;
	line-height: 1.62;
`

export const PSmall = styled.p`
	${styleParams};
	font-size: 1.2rem;
	line-height: 1.5rem;
`

export const Normal = styled.strong`
	font-weight: 400;
	color: ${({ theme }) => theme.colors.textDark};
`

export const Large = styled.span`
	font-weight: 400;
	color: ${({ theme }) => theme.colors.textLight};
	${styleParams};
`

export const Medium = styled.span`
	font-weight: 400;
	font-size: 1.4rem;
	color: ${({ theme }) => theme.colors.textLight};
	${styleParams};
`

export const Strong = styled.strong`
	font-weight: 700;
	color: ${({ theme }) => theme.colors.textDark};
	${styleParams};
`

interface AnchorProps {
	color?: 'secondary' | 'textColor' | 'textDark'
	underlined?: boolean
	fontSize?: string
	fontWeight?: string
}

export const anchorBaseStyles = css`
	cursor: pointer;
	color: ${props => props.theme.colors.primary};
	transition: color 200ms;
	background-color: transparent;
	text-decoration: underline;
	&:hover {
		color: ${props => props.theme.colors.primaryDark};
	}
`

export const Anchor = styled.a<AnchorProps>`
	${anchorBaseStyles}
	font-weight: ${({ fontWeight }) => fontWeight || '500'};
	font-size: ${({ fontSize }) => fontSize || ''};
	text-decoration: ${({ underlined }) => (underlined ? 'underline' : 'none')};
	${({ color, theme }) =>
		color === 'secondary' &&
		css`
			color: ${theme.colors.secondary};

			&:hover {
				color: ${({ theme }) => theme.colors.secondaryDark};
			}
		`}

	${({ color, theme }) =>
		color === 'textColor' &&
		css`
			color: ${theme.colors.textColor};

			&:hover {
				color: ${({ theme }) => theme.colors.textDark};
			}
		`}

	${({ color, theme }) =>
		color === 'textDark' &&
		css`
			color: ${theme.colors.textDark};

			&:hover {
				color: ${({ theme }) => theme.colors.primary};
			}
		`}
`

export const HR = styled.hr`
	display: block;
	width: 100%;
	border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
	${styleParams};
`

export const HRHideHandheld = styled(HR)`
	${media.tabletAndMobileOnly`
		display: none;
	`}
`
